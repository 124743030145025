<template>
  <div class="bg-primary d-flex filter-block w-100">
    <div class="m-24 w-40">
      <label class="label d-flex color-white mb-2">
        <img src="/assets/img/icons/search-light-white.svg" alt="" class="mx-2">
        <p>{{ $t("Free Search") }}</p>
      </label>
      <input 
        type="text" 
        class="input" 
        placeholder="Search by fair name, country, city, industry or service supplier sector"
      >
    </div>
    <div class="m-24 w-15 range-datepicker">
      <label class="label d-flex color-white mb-2">
        <img src="/assets/img/icons/calendar-alt-light-white.svg" alt="" class="mx-2">
        <p>{{ $t("Dates") }}</p>
      </label>
      <date-range-picker v-model="filter.dateRange">
      </date-range-picker>
    </div>
    <div class="m-24 w-15">
      <label class="label d-flex color-white mb-2">
        <img src="/assets/img/icons/map-marker-alt-light.svg" alt="" class="mx-2">
        <p>{{ $t("City") }}</p>
      </label>
      <vue-select
        :options="filter.test.options"
        class="input cus-select border-option-dropdown"
        v-model="filter.test.selected"
      ></vue-select>
    </div>
    <div class="m-24 w-15">
      <label class="label d-flex color-white mb-2">
        <img src="/assets/img/icons/podium-star-light.svg" alt="" class="mx-2">
        <p>{{ $t("Sector") }}</p>
      </label>
      <vue-select
          :options="filter.test.options"
          class="input cus-select border-option-dropdown"
          v-model="filter.test.selected"
        ></vue-select>
    </div>
    <button class="button filter__button m-24"></button>
  </div>
</template>

<script>
import DateRangePicker from "../../dateRangePicker2/components/DateRangePicker"

export default {
  name: 'HeaderFilter',
  components: {
    DateRangePicker
  },
  data() {
    let startDate = new Date();
    let endDate = new Date();
    endDate.setDate(endDate.getDate() + 90);
    let night = ['1', '89'];
    return {
      filter: {
        dateRange: { 
          startDate: startDate, 
          endDate: endDate, 
          night: night 
        },
        test: {
          options: [],
          selected: '',
        },
      }
    }
  }
}
</script>
<style lang="scss">
  .range-datepicker {
    min-width: 210px;
    .reportrange-text {
      height: 38px;
    }
  }
</style>

<style lang="scss" scoped>
  .filter-block {
    padding: 20px 50px;
    label {
      img{
        height: 22px;
      }
    }
    .filter__button {
      margin: 30px 8px 0;
    }
  }
  @media (max-width: 900px) {
    .filter-block {
      flex-direction: column;
      padding: 20px;
      .vue-daterange-picker {
        width: 100%;
      }
    }
    .w-40, .w-15 {
      width: 100% !important;
    }
  }
</style>